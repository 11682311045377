import style from './App.module.css'

function App() {
  return (
      <div className={style.div}>
          <h1 className={style.h1}>Внимание!</h1>
          <h2 className={style.h2}>Оплата по договору временно приостановлена по техническим причинам.</h2>
          <p className={style.p1}>Подробности по номеру горячей линии <span className={style.tell} onClick={(()=>window.open('tel:89642221070'))}>8(964)222-10-70</span></p>
          <p className={style.p2}>Звонок бесплатный</p>
      </div>
  );
}

export default App;
